<template>
  <div style="background: #F4F4F4" id="home">
    <div class="ht4"></div>
    <div class="homeUpSection">
      <div class="fs">
        <!-- <div class="homePanel1 ml15 qcard">
          <div class="m12">
            <div class="hpt1">当前运行环境</div>
            <div class="fssb mt14">
              <div>
                <div class="hl22">
                  <span class="hpt2">移动端</span>
                </div>
                <div class="hpt3">
                  <span >{{isMobile ? '是' : '否'}}</span>
                </div>
                <div class="hl22 mt6">
                  <span class="hpt2">桌面端</span>
                </div>
                <div class="hpt3">
                  <span >{{isDesktop ? '是': '否'}}</span>
                </div>
              </div>
              <div>
                <div class="hl22">
                  <span class="hpt2">企业微信</span>
                </div>
                <div class="hpt3">
                  <span >{{isWXWork ? '是': '否'}}</span>
                </div>
                <div class="hl22 mt6">
                  <span class="hpt2">微信</span>
                </div>
                <div class="hpt3">
                  <span >{{isWeixin ? '是': '否'}}</span>
                </div>
              </div>
              <div>
                <div class="hl22">
                  <span class="hpt2">普通小程序</span>
                </div>
                <div class="hpt3">
                  <span >{{isMp && !isWXWork ? '是': '否'}}</span>
                </div>
                <div class="hl22 mt6">
                  <span class="hpt2">企微小程序</span>
                </div>
                <div class="hpt3">
                  <span >{{isMp && isWXWork ? '是': '否'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>

    <div class="homeMiddleSection">
      <div class="logo">
          <img src="../../assets/logo.png" class="logoimg">
        </div>
    </div>
      <div style="height: 40px"></div>
        <div class="homeBottomSection" style="text-align: center;">
          <div class="tips"> 即将前往五粮浓香积分商城</div>
          <div style="height: 40px"></div>
          <van-button type="info" color="#C1172F" style="width: 330px" @click="handleOutWeixinClick">
              <div class="syBtnTxtHome" style="color: #fff;font-size: 14px;">立即前往</div>
            </van-button>
            <div style="height: 30px"></div>
            <van-button type="info" color="#FAE00B" style="width: 330px" @click="handleOutWeixinClick2">
              <div class="syBtnTxtHome">固定链接测试(非勤策)</div>
            </van-button>
        </div>

    <!-- {{ openlink }}
    <br> -->
    <!-- {{ ua }} -->
    <!-- {{ rq }}
    <br>
    {{ ua }} -->
    {{ href }}  /040601
    <!-- {{ showBtn }}
    移动端:{{isMobile}}
    微信端:{{isWeixin}}
    企业微信端: {{isWXWork }} -->
    <div v-html="html" style="width: 100px; height: 100px" v-show="showBtn"></div>
  </div>
</template>

<script>
// import VConsole from 'vconsole';

// const vConsole = new VConsole()
  import { Toast, Button, Tag, Icon } from 'vant';
  import wxu from '@/utils/wxu'
  export default {
    async created() {
      let isValidUrl = window.location.href.includes('code')
      let params = ''
      let pl = []
      let code = ''
      if (!isValidUrl) {
        Toast.fail('跳转链接配置错误')
        return
      }

      params = window.location.href.split('?')[1]
      pl = params.split('&')
      let codestr = pl.find(i => i.startsWith('code'))
      code = codestr.split('=')[1]
      // code跳转必要
      this.code = code
      
    },

    mounted() {
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.goBack, false)
      }
      setTimeout(() => {
        this.checEnv()
      }, 300)
    },
    destroyed() {
      window.removeEventListener('popstate', this.goBack, false)
    },
    components: {
      [Tag.name]: Tag,
      [Button.name]: Button,
      [Icon.name]: Icon,
    },
    computed: {
      p() {
        return this.$route.name
      },
    },
    data() {
      return {
        showBtn: false,
        code: '',
        html:'<wx-open-launch-weapp id="launch-btn" username="gh_c0f130f8044a" path="/pages/main/main.html" @launch="handleLaunchFn" @error="handleErrorFn"><template><style>.btn { display: flex;align-items: center; }</style><button class="btn" style="width: 100%; height: 100px">跳转小程序</button></template></wx-open-launch-weapp>',
        ua: '',
        isWeixin: 0,
        isMobile: 0,
        isWXWork: 0,
        isDesktop: 0,
        openlink: '',
        isMp: 0,
        rq: {},
        href: '',
        baseInfo: {

        },
        s: 'ruok'
      }
    },
    methods: {
      handleLaunchFn (e) {

      },
      handleErrorFn(e) {
          console.log('fail', e.detail);
      },
      // 检查打开h5页面的客户端环境
      checEnv () {
        var ua = navigator.userAgent.toLowerCase()
        this.ua = ua
        var isWXWork = ua.match(/wxwork/i) == 'wxwork'
        var isMp = ua.match(/miniprogram/i) == 'miniprogram'
        var isWeixin = !isWXWork && ua.match(/MicroMessenger/i) == 'micromessenger'
        var isMobile = false
        var isDesktop = false
        if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
          isMobile = true
        } else {
          isDesktop = true
        }
        console.warn('ua', ua)
        console.warn(ua.match(/MicroMessenger/i) == 'micromessenger')
        var m = ua.match(/MicroMessenger/i)
        console.warn(m && m[0] === 'micromessenger')
        console.log(isMobile, isWeixin, isDesktop)
        this.isMobile = isMobile
        this.isWeixin = isWeixin
        this.isWXWork = isWXWork
        this.isDesktop = isDesktop
        this.isMp = isMp
        console.log('sdadsadsa')
        // this.CreateGenerateScheme()
        if (this.isMobile && this.isWXWork) {
          window.location.href = 'weixin://dl/business/?t=I3VSAk75YFa'
          window.open('weixin://dl/business/?t=I3VSAk75YFa')
        } else if (isWeixin && !isWXWork){
          window.location.href = 'weixin://dl/business/?t=I3VSAk75YFa'
          window.open('weixin://dl/business/?t=I3VSAk75YFa')
        } else {
          try {
            this.CreateGenerateScheme()
          } catch (e) {
            // window.alert(e)
          }
        }
      },
      async handleOutWeixinClick2() {
        // await this.CreateGenerateScheme()
        if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
          location.href = 'weixin://dl/business/?t=IblqQtudo5a'
          // location.href = `opendefaultbrowser:weixin://dl/business/?t=IblqQtudo5a`
        } else {
          window.open('weixin://dl/business/?t=IblqQtudo5a')
        }
      },

      // 勤测APP跳转使用这个
      async handleOutWeixinClick() {
        // await this.CreateGenerateScheme()
        if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
          location.href = `opendefaultbrowser:${this.openlink}`
        } else {
          window.open(this.openlink)
        }
      //  window.location.href = '/dealSuccess/' + orderNum
      //   window.location.href = 'weixin://dl/business/?t=I3VSAk75YFa'
      
      },
      // 构造跳转小程序需要的参数
      async CreateGenerateScheme () {
        Toast.loading({
          message: '请求中...',
          forbidClick: true,
          overlay: true,
          duration: 0,
        })
        let params = {
          code: this.code,
          path: 'pages/pre/pre',
          query: 'loginSource=3',
          env_version: 'trial' // 测试环境
          // env_version: 'release' // 正式版
        }
        // 获取小程序link
        let res = await this.$api.CreateGenerateScheme(params)
        Toast.clear()
        this.rq = res
        let {success, code, info, data} = res
        if (success) {
          this.openlink = data.openlink
        } else {
          Toast.fail(info)
        }
      }
    }
  };
</script>

<style>
  .logoimg {
    width: 70px;
    height: 70px;
    border-radius: 15px;
  }
  .homeUpSection {
    height: 15vh;
    background: #f8f8f8;
    /* padding-bottom: 35px; */
  }
  .tips {
    padding: 20px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .homeMiddleSection {
    height: 25vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    border-bottom: 1px solid #EEE;
    /* background: linear-gradient(180deg, #FAE00B, #F4F4F4); */
  }

  .syBtnTxtHome {
    color: black;
    width: 74px;
    letter-spacing: 1px;
    font-size: 12px
  }

 #home .van-notice-bar {
   border-radius: 10px;
   width: 315px;
 }
</style>