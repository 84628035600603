<template>
  <div id="app">
    <!-- {{link}} -->
    <transition>
    <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  computed: {
    link () {return this.$store.state.initLink}
  }
}
</script>
<style>
#app {
  height: 100%;
}
body {
  font-size: 14px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
.ml15 {
  margin-left: 15px;
}
.m2 {
  margin: 2px;
}
.ml10 {
  margin-left: 10px;
}
.m10 {
  margin: 10px;
}
.m6{
  margin: 6px;
}
.ml8 {
  margin-left: 8px;
}
.mr8 {
  margin-right: 8px;
}
.ml30 {
  margin-left: 33px;
}
.mt10 {
  margin-top: 10px;
}
.mt3{
  margin-top: 3px;
}
.mt6{
  margin-top: 6px;
}
.mb10{
  margin-bottom: 10px;
}
.mt7 {
  margin-top: 7px;
}
.mb13 {
  margin-bottom: 13px;
}
.mt-12{
  margin-top: -12px;
}
.mr10{
  margin-right: 10px;
}
.mt15{
  margin-top: 15px;
}
.mt8 {
  margin-top: 8px;
}
.mt14{
  margin-top: 14px;
}
.mt4 {
  margin-top: 4px;
}
.hl23 {
  height: 22px;
  line-height: 22px;
}
.ht4 {
  height: 4px;
}
.ht10 {
  height: 10px;
}
.ht15{
  height: 15px;
}
.ht90{
  height: 90px;
}
.htg15 {
  height: 15px;
  background: gray;
}
.fs {
  display: flex;
}
.fsw {
  display: flex;
  flex-wrap:  wrap;
}
.fsac{
  display: flex;
  align-items: center;
  justify-content: center;
}
.fscc {
  display: flex;
  align-items: center;
}
.fsec2{
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row-reverse;
}
.fsbr{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.fssb{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rfssb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rfss {
  display: flex;
  flex-direction: column;
}
.fsb{
  display: flex;
  justify-content: space-between;
}
.bh {
  border: 1px solid red;
}
.border_b {
  border-bottom: #E8E8E8 1px solid;
}
.sectionTitle {
  height: 42px;
  line-height: 42px;
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 500;
}
.syBtn {
  height: 64px;
}
.syBtnTxt{
  color: black;
  width: 74px;
  letter-spacing: 1px;
  font-size:14px
}
.syBtnTxt2{
  color: black;
  width: 74px;
  letter-spacing: 1px;
  font-size:16px;
  font-weight: bold;
}
head {
  background:  linear-gradient(90deg, #F9E224, #FDC415) !important;
}
.m12 {
  margin: 12px;
}
.bold {
  text-shadow: 0px 0px 1px #333;
}
.header_z {
  background: #FAE00B;
  /* height: 110px; */
  width: 100%;

}
.qcard{
    box-shadow: 
    0 1px 1px rgba(0,0,0,0.16), 
    0 2px 2px rgba(0,0,0,0.14), 
    0 3px 1px -2px rgba(0,0,0,0.12);
    /* border-radius: 4px; */
    /* vertical-align: top;
    background: #fff */
}
/* body {
  font-family: 'DC' !important;
} */
</style>
