import axios from 'axios'
const prefix = ''
import Vue from 'vue'
const S = window.sessionStorage
const L = window.localStorage
async function get (url, params = {}) {
  let allData
  let token = L.getItem('token')
  let headers = token ? {'Token': token} : {}
  try {
    let {data} = await axios.get(url, {params, headers}, )
    // Vue.$route.replace('index')
    allData = data
  } catch (e) {
    allData = { success: false, info: '网络错误' }
  }
  let {authentication} = allData
  if ('authentication' in allData && !authentication) {
    window.sessionStorage.removeItem('l1')
    window.location.replace('/')
    // Vue.$router.replace('index')
  }
  return allData
}
async function post (url, params) {
  let allData
  let formData = new window.FormData()
  for (let key in params) {
    let value = params[key]
    // if (value === 0) value = '0'
    // if (!value) continue
    if (typeof (value) === 'object') {
      for (let i in value) {
        formData.append(key + '[]', value[i])
      }
    } else {
      formData.append(key, value)
    }
  }
  let token = L.getItem('token')
  let headers = token ? {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 'Token': token} : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
  // formData.append('Sign', sign(params))
  try {
    let {data} = await axios.post(url, formData, {headers})
    allData = data
  } catch (e) {
    console.table(e.response)
    allData = { success: false, info: e?.response?.data?.info || '网络错误' }
  }
  let {authentication} = allData
  if ('authentication' in allData && !authentication) {
    window.sessionStorage.setItem('l1')
    window.location.replace('/')
    // Vue.$router.replace('index')
  }
  return allData
}

async function del (url, params = {}) {
  let allData
  try {
    let {data} = await axios.delete(url, {params})
    allData = data
  } catch (e) {
    allData = { success: false, info: '网络错误' }
  }
  return allData
}

export default {
  CreateGenerateScheme (params = {}) { // 获取链接
    return post(prefix + '/mini/WeChat/CreateGenerateScheme', params)
  },
  // getJsSdkConfig (params = {}) { // 获取jssdk配置
  //   return get(prefix + '/WeChat/GetConfig', params)
  // }
}
