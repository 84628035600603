import Vue from 'vue';
import App from './App';
import { router } from './router';
import {store} from './store'
import { VueJsonp } from 'vue-jsonp'    // 网上很多博客引用不加{}，会报错
import './common/font.css'
import 'vant/lib/nav-bar/style/less'
import 'vant/lib/divider/style/less'
import 'vant/lib/calendar/style/less'
import 'vant/lib/tabbar/style/less'
import 'vant/lib/tabbar-item/style/less'
import 'vant/lib/cell/style/less'
import 'vant/lib/field/style/less'
// import 'vant/lib/index.less'
Vue.use(VueJsonp)
// import {obStore} from './store'
import api from './requests'

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

router.afterEach((to, from) => {
  let path = to.fullPath.slice(1) // 去除'/'
  if (!sessionStorage.getItem('initLink')) {
    // 解决ios微信下，分享签名不成功的问题,将第一次的进入的url缓存起来。
    sessionStorage.setItem('initLink', document.URL)
  }
  let url
  if (!window.__wxjs_is_wkwebview) {
    // ios
    url = sessionStorage.getItem('initLink')
  } else {
    // 安卓 process.env.BASE_URL 自己定义各个环境下域名变量
    url = location.origin + process.env.BASE_URL + path
  }
    console.log(url)
    // url = location.origin + process.env.BASE_URL + path
    store.commit('mSetInitLink', url)
})

Vue.prototype.$api = api
// Vue.prototype.$obStore = obStore
new Vue({
  router,
  el: '#app',
  store,
  render: h => h(App)
});
